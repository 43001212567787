import { useFormik } from "formik";
import { UserState } from "../../utils/types";
import { useState } from "react";
import { CreateUserValidationSchema } from "../../utils/validateSchemaUtil";

const Utils = () => {
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const InitialValues: UserState = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    roleDesc:"",
    isActive: false,
  };

  const onSubmit = () => {
    setIsSubmit(true);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    touched,
    setTouched,
    setFieldTouched,
    isValid
  } = useFormik({
    initialValues: InitialValues,
    validationSchema: CreateUserValidationSchema,
    validateOnMount:true,
    onSubmit,
    
  });

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    touched,
    setTouched,
    setFieldTouched,
    isSubmit,
    onSubmit,
    isValid
  };
};

export default Utils;
