import CreateUser from "./CreateUser/createUser";
import UsersList from "./UsersList";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../redux/selectors/selectors";
import { useState } from "react";
import { SubmenuEnum } from "../../utils/enum";

const ManageUser = (props: any) => {

  const [selectedRows, setSelectedRows] = useState<any>({id:-1,firstName:'',lastName:'',email:'',phoneNo:'',roleDesc:'',isActive:false})
  const color = useSelector(selectThemeColor);

  const handleEditSelect = (row: any) => {
    if (row) {
      const { id,first_name, email, phone_number, roleDesc, status } = row;
      setSelectedRows({
        id: id,
        firstName: first_name.split(" ")[0],
        lastName: first_name.split(" ")[1],
        email: email,
        phoneNo: phone_number,
        roleDesc:roleDesc,
        isActive: status === "A" ? true : false,
      });
    } else {
      setSelectedRows({
        id: -1,
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: false,
      });
    }
  };

  return (
    <>
      {!props.isBack ? (
        <>
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              className="btn  mb-2"
              style={{width:'10vw', backgroundColor: color.darkColor, color: "white" }}
              onClick={() => {
                props.getSubMenu(SubmenuEnum.ADD_USER);
                props.setBackButton(true);
                setSelectedRows({
                  id: -1,
                  firstName: "",
                  lastName: "",
                  email: "",
                  phoneNo: "",
                  roleDesc:"",
                  isActive:false
                });
              }}
            >
              <FontAwesomeIcon className="mx-1" icon={faUserPlus} size="sm" />
              <span>Add User</span>
            </Button>
          </div>
          <UsersList
            setBack={props.setBackButton}
            getSubMenu={props.getSubMenu}
            onEditSelect={handleEditSelect}
          />
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between m-2"></div>
          <CreateUser
            setBackButton={props.setBackButton}
            isBack={props.isBack}
            selectedRows={selectedRows}
          />
        </>
      )}
    </>
  );
};

export default ManageUser;
