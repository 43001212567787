import * as Yup from "yup";
import { useFormik } from "formik";
import { OnboardFormState } from "../../utils/types";
import { UserRoleEnum } from "../../utils/enum";
import UseAuth from "../../hooks/useAuth";

const Utils = (props: any) => {

  const {isAuthenticated} =  UseAuth();

  const ValidationSchema = () => {
    switch (props) {
      case 0:
        return Yup.object().shape({
          orgName: Yup.string().when('role', (role, schema) => {
            return role[0] !== UserRoleEnum.OWNER
              ? schema.required("Required!")
              : schema.trim();
          }),
          name: Yup.string()
            .required("Required!")
            .trim(),
          email: Yup.string().email("Invalid email").required("Required!").trim(),
          phoneNumber: Yup.string()
            .matches(
              /^((\\+[1-9]{1,4}[ \-]*)|(\\([0-9]{2,3}\\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/,
              "Phone number is not valid"
            ).trim()
            .required("Required!"),
          password: getPasswordValidation(isAuthenticated),
          confirmPassword: getConfirmPasswordValidation(isAuthenticated),
          addressOne: Yup.string().trim(),
          addressTwo: Yup.string().trim(),
          state: Yup.string().required("Required!"),
          city: Yup.string().required("Required!").trim(),
          zip: Yup.string()
            .required("Required!")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(5, "Must be exactly 5 digits")
            .max(5, "Must be exactly 5 digits"),
          taxNo: Yup.string().matches(/^[a-zA-Z0-9]*$/, "Invalid Field"),
          licenseNo: Yup.string().matches(/^[a-zA-Z0-9]*$/, "Invalid Field"),
        });
      case 1:
        return Yup.object().shape({
          laborAffiation: Yup.string().required("Required!"),
        });
      case 2:
        return Yup.object().shape({
          tradeList: Yup.array()
          .min(1, 'At least one trade is required')
          .required("Required!")
        });
      default:
        return Yup.object().shape({});
    }
  };

  const getPasswordValidation = (isAuthenticated: boolean) => {
    if (isAuthenticated) {
      return Yup.string().trim(); // If authenticated, just trim the password (no strong validation)
    }
  
    // If not authenticated, apply all strong password rules
    return Yup.string()
      .required("Required!")
      .min(8, "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number, and special character (e.g., Unnipatt@123)")
      .matches(/[a-z]+/, "At least one lowercase character")
      .matches(/[A-Z]+/, "At least one uppercase character")
      .matches(/[@$!%*#?&]+/, "At least one special character")
      .matches(/\d+/, "At least one number")
      .matches(/^\S*$/, "White spaces are not allowed");
  };

  const getConfirmPasswordValidation = (isAuthenticated: boolean) => {
    if (isAuthenticated) {
      return Yup.string().trim(); // If authenticated, just trim the confirm password
    }
  
    // If not authenticated, confirm password must match the password
    return Yup.string()
      .required("Required!")
      .oneOf([Yup.ref("password")], "Passwords must match")
      .trim();
  };


  const DefaultOnboardFormData: OnboardFormState = {
    orgName: "",
    password: "",
    confirmPassword: "",
    name: "",
    email: "",
    phoneNumber: "",
    addressOne: "",
    addressTwo: "",
    state: "",
    zip: "",
    licenseNo: "",
    dirDate: null,
    classifications: [],
    projectEstName: "",
    projectEstEmail: "",
    projectEstPhoneNo: "",
    laborAffiation: "",
    taxNo: "",
    tradeList: [],
    city: "",
    dirNumber: null,
    role: "",
    licenseClassifications: []
  };

  const onSubmit = async () => {};

  const { values, errors, handleChange, handleSubmit, handleReset, setFieldValue, setFieldError, touched, setTouched, setFieldTouched, isValid, setErrors } = useFormik({
    initialValues: DefaultOnboardFormData,
    validationSchema: ValidationSchema,
    onSubmit,
    validateOnMount: true
  })

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldError,
    touched,
    setTouched,
    setFieldTouched,
    isValid,
    onSubmit,
    setErrors,
  };
}
export default Utils;


