import {
  Box,
  Tab,
  Badge,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";
import SelectDivisionSpecialization from "../../SelectDivisionAndSpecification/selectDivisionAndSpecification";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { useCallback, useEffect, useRef, useState } from "react";
import TabPanel from "@mui/lab/TabPanel";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import { useSelector } from "react-redux";
import {
  selectProjectId,
  selectThemeColor,
} from "../../../redux/selectors/selectors";
import ProjectBidsForm from "../ProjectBidsForm/projectBidsForm";
import ProjectDocument from "../ProjectDocument/projectDocument";
import ProjectSubList from "../ProjectSubList/projectSubList";
import { useDispatch } from "react-redux";
import { setCustomTableId } from "../../../redux/actions/projectFormActions";
import BidInviteModal from "../../BidInviteModal/bidInviteModal";
import {
  getCountOfQueFromSubs,
  getCountOfSubReq,
  getAllQuestionsFromSubs,
  getSubRequestList,
  sendInviteSubContractor,
  updateSubReqStatus,
  getProjectTradeAndCountList,
} from "../../../utils/api";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faLock } from "@fortawesome/free-solid-svg-icons";
import { SubRequestAccessListType, SubsQueRow } from "../../../utils/types";
import { CustomTable } from "../../DataTable";
import QuestionFromSubs from "../QuestionFromSubs/questionFromSubs";
import SelectInputField from "../../SelectInputField/selectInputField";
import { DataUtil } from "../../../utils/dataUtils";
import BidReportModal from "../../BidReportModal/bidReportModal";
import UsePermission from "../../../hooks/usePermission";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { iSAllSubSelected } from "../../InviteSubDropdownField/inviteSubDropdownField";

interface ChildRef {
  refreshSubList: (message: string) => void;
}

const ProjectBids = (props: any) => {

  const [tabValue, setTabValue] = useState<string>("details");
  const [showSnackBar, setShowsnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [toOpenEmailModal, setToOpenEmailModal] = useState(false);
  const [projectTradeCodeList, setProjectTradeCodeList] = useState<string[]>([]);
  const [queCount, setQueCount] = useState<number>(0);
  const [toOpenSubRequestModal, setToOpenSubRequestModal] = useState(false);
  const [subReqestListRows, setSubReqestListRows] = useState<SubRequestAccessListType[]>([]);
  const [subReqCount, setSubReqCount] = useState<number>(0);
  const [subQueModal, setSubQueModal] = useState<boolean>(false);
  const [subsQueRow, setSubsQueRow] = useState<SubsQueRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toOpenReportModal, setToOpenReportModal] = useState(false);
  const [open, setOpen] = useState(false);

  const projectSubListRef = useRef<ChildRef>(null);

  const disptch = useDispatch();
  const projectId = useSelector(selectProjectId);
  const {isOwner}  = UsePermission();

  const tabValues = projectId
    ? [
        {
          label: (
            <label>
              Project: <strong>{props.projectName}</strong>
            </label>
          ),
          value: "projectName",
        },
        { label: "Email", value: "email" },
        { label: "Reports", value: "reports" },
        { label: "Questions From Subs", value: "questionsFormSub" },
        { label: "Subs Request", value: "subRequest" },
      ]
    : [];

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!projectId) {
      setTabValue("details");
    }else{
      setTabValue("subList");
      getCountOfQueFromSubs("", projectId, undefined).then((resp) => {
        if (resp.status === "OK") {
          if (resp.data === null) {
            setQueCount(0);
          } else {
            console.log(resp.data);
            setQueCount(resp.data);
          }
        }
      });
    }
    fetchGetSubReqListApi("requested");
  }, [projectId]);

  useEffect(() => {
    if (projectId && !toOpenSubRequestModal) {
      getCountOfSubReq("", projectId, undefined).then((resp) => {
        if (resp.data) {
          setSubReqCount(resp.data);
        } else {
          setSubReqCount(0);
        }
      });
    }
  }, [projectId, toOpenSubRequestModal]);

  const fetchGetSubReqListApi = useCallback((status: string) => {
    if(projectId){
      setIsLoading(true);
      getSubRequestList("", { projectId, status: status }, undefined)
      .then((resp) => {
        if (resp === null || resp.data === new Map()) {
          return;
        }
        setSubReqestListRows(resp.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    }
  
  }, []);

  const handleChange = (_event: any, newValue: string) => {
    if (isOwner && newValue === "email") {
      setOpen(true);
      return;
    }
    setTabValue(newValue);
  };

  const handleCloseQnAModal = ()=>{
    setSubQueModal(false);
    getCountOfQueFromSubs("", projectId, undefined).then((resp) => {
      if (resp.status === "OK") {
        if (resp.data === null) {
          setQueCount(0);
        } else {
          console.log(resp.data);
          setQueCount(resp.data);
        }
      }
    });
  }

  const renderTabContent = (
    tabValue: string,
    queCount: number,
    subReqCount: number
  ) => {
    switch (tabValue) {
      case "questionsFormSub":
        return (
          <Badge
            className="size-sm px-1"
            badgeContent={queCount}
            color="primary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            showZero
          />
        );
      case "subRequest":
        console.log("subReqCount", subReqCount);
        return (
          <Badge
            className="size-sm px-1"
            badgeContent={subReqCount}
            color="primary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            showZero
          />
        );
      case "email":
      case "reports":
      case "subRequest":
      case "questionsFormSub":

        if (isOwner) {
          return (
            <FontAwesomeIcon color="primary" className="btn" icon={faLock} />
          );
        }
        return <></>;
      default:
        return <></>;
    }
  };

  const tab = tabValues.map((tab) => {
    return (
      <Tab
        className="border px-5 ms-2"
        key={tab.value}
        label={tab.label}
        value="0"
        icon={renderTabContent(tab.value, queCount, subReqCount)}
        iconPosition="end"
        onClick={() => handleTabClick(tab.value)}
        wrapped={true}
      />
    );
  }); 

  const handleTabClick = (value: string) => {
    debugger
    switch (value) {
      case "email":
        if (isOwner) {
          setOpen(true);
          return;
        }
        setToOpenEmailModal(true);
        getProjectTradeAndCountList("", projectId, undefined)
          .then((resp) => {
            if (resp === null || resp.data === new Map()) {
              return;
            }
            setProjectTradeCodeList(resp.data.projectTradeCode);
          })
          .catch(() => {
            setProjectTradeCodeList([]);
          });
        break;
      case "subRequest":
        if (isOwner) {
          setOpen(true);
          return;
        }
        setToOpenSubRequestModal(true);
        fetchGetSubReqListApi("requested");
        break;
      case "questionsFormSub":
        if (isOwner) {
          setOpen(true);
          return;
        }
        setSubQueModal(true);
        getAllQuestionsFromSubs("", { projectId, sector: "" }, undefined)
          .then((resp) => {
            if (resp.status === "OK") {
              console.log(resp.data);
              const updatedData = resp.data.map((item: any) => {
                const recDateTime: string[] = item.receivedDate.split(" ");
                const ansDateTime: string[] =
                  item.answeredDate === null
                    ? ["", "", ""]
                    : item.answeredDate.split(" ");

                return {
                  ...item,
                  receivedDate:
                    recDateTime[0] +
                    " " +
                    recDateTime[1] +
                    " " +
                    recDateTime[2],
                  receivedTime: recDateTime[1] + " " + recDateTime[2],
                  answeredDate:
                    ansDateTime[0] +
                    " " +
                    ansDateTime[1] +
                    " " +
                    ansDateTime[2],
                  answeredTime: ansDateTime[1] + " " + ansDateTime[2],
                };
              });

              setSubsQueRow(updatedData);
            }
          })
          .catch((error) => {
            console.log("error", error);
            setSubsQueRow([]);
          });
        break;
      case "reports":
        if (isOwner) {
          setOpen(true);
          return;
        }
          setToOpenReportModal(true);
        break;
      default:
        break;
    }
  };

  const handleSendInvite = ({subject, emailScope, files, inviteType, subMails, extraMessage, projectTradeIds = []} : any) => {
    debugger
    setIsLoading(true);
    const formData = new FormData();
    const body: any = {
      projectId,
      projectTradeId: null,
      subContractorMails: subMails,
      subject,
      emailBody: emailScope,
      inviteType,
      status: "Invited",
      extraMessage,
    };
    
    if (!iSAllSubSelected) {
      body.projectTradeIds = projectTradeIds;
    }
    
    formData.append('emailRequest', JSON.stringify(body));
    files.forEach((file: any, index: number) => {
      const { blob, name } = DataUtil.createNewBlocAndFileName(file, index);
      formData.append("emailAttachments", blob, name);
    });

    if (subMails.length > 0) {
      sendInviteSubContractor("", undefined, formData)
        .then((response) => {
          setToOpenEmailModal(false);
          setMessage(response.message);
          if(tabValue === "subList" && projectSubListRef.current){
            projectSubListRef.current.refreshSubList(response.message)
          }
          setIsLoading(false);
          setShowsnackBar(true);
          setMessage(response.message);
        })
        .catch((error: any) => {
          setToOpenEmailModal(false);
          setIsLoading(false);
          setShowsnackBar(false);
          setMessage(error.message);
        });
    } else {
      setIsLoading(false);
      setMessage("Please Check invite mail");
    }
  };

  const handleBidReqStatusChange = (e: any) => {
    fetchGetSubReqListApi(e.target.value.toLowerCase());
  };

  const handleClickOnStatus = (row: any, status: string, checked: boolean) => {
    setIsLoading(true);
    disptch(setCustomTableId(row.subRequestId))
    updateSubReqStatus(
      "",
      { status: status, subRequestId: row["subRequestId"] },
      undefined
    )
      .then(() => {
        fetchGetSubReqListApi("requested");
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <TabContext value={0}>
        <TabList
          aria-label="project-tab"
          sx={{
            borderRadius: "4px",
            "& .MuiTabs-indicator": {
              backgroundColor: "transparent",
            },
          }}
          selectionFollowsFocus={true}
          variant="scrollable"
        >
          <div className="col-10 offset-1 px-1 pointer">{tab}</div>
        </TabList>
      </TabContext>
      <div className="d-flex h-100">
        <TabContext value={tabValue}>
          <Box sx={{ width: "10%", color: "black" }}>
            <TabList
              onChange={handleChange}
              aria-label=""
              sx={{
                borderRadius: "4px",
                "& .MuiTabs-indicator": {
                  backgroundColor: "black",
                },
              }}
              orientation="vertical"
              variant="scrollable"
            >
              <Tab label="Trades" value="trades" disabled={!projectId} />
              <Tab label="Sublist" value="subList" disabled={!projectId} />
              <Tab label="Files" value="files" disabled={!projectId} />
              <Tab label="Details" value="details" />
            </TabList>
          </Box>
          <TabPanel className="p-0 h-75 w-100  shadow bg-light" value="trades">
            <div style={{ overflowY: "scroll" }}>
              <SelectDivisionSpecialization
                formikProps={{
                  showTradeLable: true,
                  setBackButton: props.setBackButton,
                }}
              />
            </div>
          </TabPanel>
          <TabPanel
            className="p-0 pt-3 h-75 w-100 shadow bg-light"
            style={{ overflow: "hidden" }}
            value="subList"
          >
            <div className="container-fluid">
              {isOwner ? (
                <>
                  <Typography
                    variant="h5"
                    className="d-flex opacity-70 justify-content-center mt-5 py-5 fs-3"
                  >
                    You do not have permission to access this resource.
                  </Typography>
                  <Typography
                    variant="h5"
                    className="d-flex opacity-70 justify-content-center  fs-3"
                  >
                    Please contact your administrator for assistance or check
                    your access privileges.
                  </Typography>
                </>
              ) : (
                <ProjectSubList
                  key={tabValue}
                  headerCells={[]}
                  projectName={props.projectName}
                  ref={projectSubListRef}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel
            className="p-0 pt-3 w-100 h-75 shadow bg-light"
            style={{ overflow: "hidden" }}
            value="files"
          >
            <div className="container-fluid">
              <ProjectDocument key={tabValue} />
            </div>
          </TabPanel>
          <TabPanel
            className="p-0 pt-3 w-100 h-75 shadow bg-light"
            style={{ overflow: "scroll" }}
            value="details"
          >
            <div className="container">
              <ProjectBidsForm
                key={tabValue}
                setBackButton={props.setBackButton}
              />
            </div>
          </TabPanel>
        </TabContext>
      </div>

      <CustomizedSnackbar
        openSnackBar={showSnackBar}
        message={message}
        handleCloseSnackBar={() => setShowsnackBar(false)}
      />
      <BidInviteModal
        isOpen={toOpenEmailModal}
        handleClose={() => setToOpenEmailModal(false)}
        selectFieldList={projectTradeCodeList}
        handleSubmit={handleSendInvite}
        showAllMails={true}
        isLoading={isLoading}
        title={props.projectName}
      />
      <SubRequestModal
        isOpen={toOpenSubRequestModal}
        handleClose={() => setToOpenSubRequestModal(false)}
        subReqestListRows={subReqestListRows}
        handleClickOnStatus={handleClickOnStatus}
        handleBidReqStatusChange={handleBidReqStatusChange}
        isLoading={isLoading}
        projectName={props.projectName}
      />
      <QuestionFromSubs
        isOpen={subQueModal}
        isSender={false}
        handleClose={handleCloseQnAModal}
        rowData={subsQueRow}
        projectName={props.projectName}
      />
      <BidReportModal
        isOpen={toOpenReportModal}
        handleClose={() => setToOpenReportModal(false)}
        title={props.projectName}
      />
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setOpen(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          You do not have permission to access this resource. Please contact
          your administrator for assistance or check your access privileges.
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProjectBids;


interface Props {
  isOpen: boolean;
  handleClose: () => void;
  subReqestListRows:SubRequestAccessListType[]
  handleUpdateNewMails?: (newMail: string) => void;
  isLoading?: boolean;
  handleClickOnPreview?: (viewMailPreview : boolean) => void;
  handleClickOnStatus?:(row :any, actionType:string,checked:boolean) => void;
  handleBidReqStatusChange?:(e : any)=> void;
  projectName:string;
}

const SubRequestModal = ({
  isOpen = false,
  handleClose,
  subReqestListRows,
  handleClickOnStatus,
  handleBidReqStatusChange,
  isLoading = false,
  projectName
}: Props) => {
  const color = useSelector(selectThemeColor);
  const headCells = [
    { id: "date", name: "Date", action: "date" },
    { id: "orgName", name: "Company", action: "companyHover" },
    { id: "requestType", name: "Request Type", action: "", align: "left" },
    { id: "action", name: "Action", action: "subRequestAccess" },
  ];


  return (
    <Modal
      id="viewInvite"
      className="position-fixed m-2"
      isOpen={isOpen}
      ariaHideApp={false}
      style={{
        content: {
          position: "fixed",
          top: "auto",
          left: "auto",
          right: "0",
          bottom: "0",
          width: "700px",
          height: "535px",
          padding: "0",
          borderRadius: "10px",
          backgroundColor: "white",
          border: "1px solid #ccc",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
          transition: "width 0.3s ease, height 0.3s ease",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 1000,
          pointerEvents: "auto",
          transition: "background-color 0.3s ease, opacity 0.3s ease",
          opacity: 1,
        },
      }}
    >
      <div className="modal-dialog modal-2x">
        <div className="modal-content">
          <div className="modal-header row gx-0">
            <div className="col-11">
              <h5 className="mt-1">
                Public Bid Requests : <strong>{projectName}</strong>
              </h5>
            </div>
            <div className="col-1 d-flex justify-content-end">
              {!isLoading && (
                <FontAwesomeIcon
                  className="pointer"
                  icon={faClose}
                  size="2x"
                  color={color.mediumColor}
                  onClick={() => {
                    handleClose();
                  }}
                />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end m-3">
            <SelectInputField
              id="Status"
              name="Status"
              label="Select Status"
              defaultValue="Requested"
              formControlClassName="col-4"
              labelClassName="bg-white"
              selectClassName="bg-white w-auto"
              menuClassName="d-block ms-3"
              menuList={["Accepted", "Declined", "Requested"]}
              handleChange={handleBidReqStatusChange}
            />
          </div>

          <div className="container">
            <CustomTable
              headCells={headCells}
              rows={subReqestListRows}
              onSelectRowClick={handleClickOnStatus}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

