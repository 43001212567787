import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { forwardRef, memo, useCallback, useMemo } from "react";

interface Props {
  id: string;
  name: string;
  value: string | number | null;
  defaultValue?: any;
  label: string;
  handleChange?: (_event: any) => void;
  error?: any;
  readonly?: boolean;
  disabled?: boolean;
  shrink?: boolean;
  required?: boolean;
  helperText?: string;
  handleBlur?: () => void;
  handleOnClick?: () => void;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: string;
}

const DatePickerField = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      label,
      value,
      required,
      helperText,
      error,
      handleChange,
      readonly = false,
      disabled = false,
      disablePast = false,
      disableFuture = false,
      minDate,
    },
    ref
  ) => {
    const formattedValue = useMemo(() => {
      return value ? dayjs(value).format("MM/DD/YYYY") : null;
    }, [value]);
    const onChange = useCallback(
      (newValue: string | number | dayjs.Dayjs | Date | null | undefined) => {
        const formattedDate = newValue
          ? dayjs(newValue).format("YYYY-MM-DD")
          : "";
        handleChange!({ target: { name, value: formattedDate } });
      },
      [handleChange, name]
    );
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-us">
        <DemoContainer sx={{ margin: "0" }} components={["DatePicker"]}>
          <DatePicker
            className="form-control"
            ref={ref}
            name={name}
            label={label}
            value={value ? dayjs(formattedValue, "MM/DD/YYYY", true) : null}
            slotProps={{
              openPickerButton: {
                color: "primary",
                edge: "end",
              },
              textField: {
                color: "primary",
                size: "small",
                className: "bg-white form-control",
                required:required,
                helperText: error ? "Invalid date" : helperText,
                error: error
              },
              inputAdornment: {
                position: "end",
              },
              layout: {
                sx: {
                  color: "#19255c",
                },
              },
              field: { shouldRespectLeadingZeros: true },
            }}
            onChange={onChange}
            openTo="day"
            views={["year", "month", "day"]}
            yearsOrder="desc"
            sx={{
              ".MuiPickersToolbar-root": {
                borderRadius: "2px",
                borderWidth: "1px",
                border: "1px solid",
              },
              ".MuiOutlinedInput-input": {
                background: readonly ? "#eee" : "white",
              },
            }}
            orientation="landscape"
            readOnly={readonly}
            disablePast={disablePast}
            disableFuture={disableFuture}
            disabled={disabled}
            minDate={minDate ? dayjs(minDate, "YYYY-MM-DD", true) : undefined}
          />
        </DemoContainer>
      </LocalizationProvider>
    );
  }
);

DatePickerField.displayName = "DatePickerField";
export default memo(DatePickerField);
