export const SubmenuEnum = {
  NEW_PROJECT: "New Project",
  BID_OPPORTUNITIES_NETWORK: "Bid Opportunities Network",
  MY_BIDS: "My Bids",
  POST_BIDS: "Post Bids",
  EDIT_PROJECT: "Edit Bids",
  ADD_USER: "Add User",
  EDIT_USER: "Edit User",
  INVITED_BIDS: "Invited Bids",
  EDIT_ORGANIZATION: "Organization Info",
};

export const UserStatusEnum = {
A:"A",
R:"R"
};

export const ResponseEnum = {
  Y: "Y",
  N: "N",
};

export const PostProjectEnum = {
  ACTIVELY_BIDDING: "Actively Bidding",
  POST_BIDDING: "Post Bid",
  UNDER_CONSTRUCTION: "Under Construction",
  CLOSED: "Closed",
  DRAFT: "Draft",
};

export const BidStatusEnum = {
  VIEWED:"Viewed",
  INVITED:"Invited",
  ACCEPTED:"Accepted",
  REJECTED:"Rejected",
  MAY_BE:"May Be",
  BID_SUBMITTED:"Bid Submitted",
  DRAFT: "Draft",
};

export const PublicBidAccessEnum = {
  NO_REQUEST: "Open to All - No Request",
  REQUEST_REQUIRED: "Open to All - Request Required",
  INVITED_ONLY: "Invited Only",
};


export const UserRoleEnum = {
  OWNER:"Owner",
  OWNER_USER:"Owner User",
  GC:"GC",
  GC_USER:"GC User",
  SC:"SC",
  SC_USER:"SC User",
  SYSTEM_ADMIN: "SysAdmin",
  SYSTEM_ADMIN_USER: "SysAdmin User",
  CONTRACTOR:""
};
