import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { LaborAffilication } from "../../../utils/const";
import { useEffect, useMemo, useRef, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputField from "../../InputField";
import { getOrgClassification } from "../../../utils/api";
import Loader from "../../Loader";
import { DataUtil } from "../../../utils/dataUtils";
import { UserRoleEnum } from "../../../utils/enum";
import { DatePickerField } from "../../DateAndTimePickerField";
import SelectInputField from "../../SelectInputField/selectInputField";


const ClassiFicationDetails = ({ formikProps, isProfileUpdating,classificationAndLicence }: any) => {

  const [isLoading, setIsLoading] = useState(false)
  const { values, handleChange, setFieldValue, errors, setFieldError, touched } = formikProps;
  const LaborAffiliations = LaborAffilication;
  const dateRef: any = useRef();

  const isUserOwner = useMemo(() => {
    return values.role === UserRoleEnum.OWNER;
  }, [values.role]);

  useEffect(() => {
    if (!values.laborAffiation)
      setFieldError("laborAffiation", "Required!");
  }, []);

  useEffect(() => {
    if (values.classifications.length === 0 || isProfileUpdating) {
      setIsLoading(true);
      getOrgClassification("", values.role, undefined)
        .then((response) => {
          if (DataUtil.isArrayAndNotEmpty(response.data.classification)) {
            let orgSelected : any = [];
            if(isProfileUpdating){
              orgSelected = classificationAndLicence.classification;
            }
            const classificationData = response.data.classification.map(
              (value: any) => ({
                ...value,
                isSelected: isProfileUpdating ? orgSelected.some((id: any) => id === value.id) : false,
              })
            );
            setFieldValue("classifications", classificationData);
          } else {
            setFieldValue("classifications", []);
          }

          if (
            DataUtil.isArrayAndNotEmpty(response.data.licenseClassifications)
          ) {
            let orgSelected: any = [];
            if (isProfileUpdating) {
              orgSelected = classificationAndLicence.licenceClassification;
            }
            const licenseClassificationData =
              response.data.licenseClassifications.map((value: any) => ({
                ...value,
                isSelected: isProfileUpdating && orgSelected.some((data: any) => data === value.id),
              }));

            console.log("licenseClassificationData", licenseClassificationData);
            setFieldValue("licenseClassifications", licenseClassificationData);
          } else {
            setFieldValue("licenseClassifications", []);
          }
        })
        .catch((error: any) => {
          console.log("error", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);
  

  return (
    <>
      <div className="row  gap-1 mt-3">
        <div className="form-floating col-3 offset-2">
          {/* <FormControl fullWidth>
            <InputLabel
              id="select-label"
              size="small"
              required
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
                fontFamily: "SenticosansDTCond Thin",
              }}
            >
              Labor Affiliation
            </InputLabel>
            <Select
              labelId="select-label"
              id="laborAffiation"
              name="laborAffiation"
              value={values.laborAffiation}
              label="Labor Affiliation"
              className="bg-white"
              onChange={handleChange}
              size="small"
              inputProps={{}}
            >
              <MenuItem value="" key={-1}></MenuItem>
              {LaborAffiliations.map((laborAff: any, index: any) => (
                <MenuItem key={index} value={laborAff}>
                  {laborAff}
                </MenuItem>
              ))}
            </Select>
            {touched.laborAffiation && errors.laborAffiation && (
              <span className="text-danger">{errors.laborAffiation}</span>
            )}
          </FormControl> */}
          <SelectInputField
            id="laborAffiation"
            name="laborAffiation"
            value={values.laborAffiation}
            selectClassName="bg-white"
            labelClassName="bg-white"
            menuClassName="d-block ms-2"
            handleChange={handleChange }
            label="Labor Affiliation *"
            menuList={LaborAffiliations}
            error={errors.laborAffiation}
            required
          />
        </div>
        <div className="col-2">
          <InputField
            id="dirNumber"
            name="dirNumber"
            label="DIR Number"
            type="text"
            value={(values.dirNumber ?? "").toString().replace(/[^0-9]/g, "")}
            className="form-control"
            handleChange={handleChange}
            error={touched.dirNumber && errors.dirNumber}
          />
        </div>
        <div className="col-3 py-0">
          <DatePickerField
            id="dirDate"
            name="dirDate"
            value={values.dirDate}
            handleChange={handleChange}
            label="DIR Expiry Date"
          />
        </div>
      </div>
      <div className="row justify-content-center gap-1 mt-5 ms-5">
        <h4 className="opacity-70" style={{ fontSize: "24px" }}>
          Classifications:
          {errors.classifications && (
            <span className="ms-2 text-danger size-lg">
              {errors.classifications as any}
            </span>
          )}
        </h4>
        {isLoading ? (
          <Loader
            className="d-flex justify-content-center mt-5 py-5 px-5"
            label="Fetching Classifications"
          />
        ) : (
          values.classifications.map((classFication: any, index: number) => (
            <div className={`${isUserOwner ? "col-3" : "col-1"} p-0`}>
              <FormControlLabel
                labelPlacement="end"
                className="size-6x"
                control={
                  <Checkbox
                    className="theme-check size-lg"
                    checked={classFication.isSelected}
                    name={classFication.classificationCode}
                    value={!classFication.isSelected}
                    onChange={(e: any) => {
                      setFieldValue(
                        `classifications[${index}].isSelected`,
                        !classFication.isSelected
                      );
                    }}
                    size="medium"
                  />
                }
                label={classFication.classificationCode}
              />
            </div>
          ))
        )}
      </div>
      {!isUserOwner && (
        <div className="row justify-content-center gap-1 mt-5 ms-5">
          <h4 className="opacity-70" style={{ fontSize: "24px" }}>
            License Classifications:
            {errors.licenseClassifications && (
              <span className="ms-2 text-danger size-lg">
                {errors.licenseClassifications as any}
              </span>
            )}
          </h4>
          {isLoading ? (
            <Loader
              className="d-flex justify-content-center mt-5 py-5 px-5"
              label="Fetching Classifications"
            />
          ) : (
            values.licenseClassifications.map(
              (licenseClassifications: any, index: number) => (
                <div className="col-2 p-0">
                  <FormControlLabel
                    labelPlacement="end"
                    className="size-6x"
                    control={
                      <Checkbox
                        className="theme-check size-lg"
                        checked={licenseClassifications.isSelected}
                        name={licenseClassifications.licenseClassificationName}
                        value={!licenseClassifications.isSelected}
                        onChange={(_e: any) => {
                          setFieldValue(
                            `licenseClassifications[${index}].isSelected`,
                            !licenseClassifications.isSelected
                          );
                        }}
                        size="medium"
                      />
                    }
                    label={licenseClassifications.licenseClassificationName}
                  />
                </div>
              )
            )
          )}
        </div>
      )}
      {}
    </>
  );
}

export default ClassiFicationDetails;
