import { UserRoleEnum } from "../utils/enum";

const UsePermission = () => {
  const roleFromLocalStorage = localStorage.getItem("role");
  const isOwner =
    roleFromLocalStorage === UserRoleEnum.OWNER ||
    roleFromLocalStorage === UserRoleEnum.OWNER_USER;
  const isSubContractor =
    roleFromLocalStorage === UserRoleEnum.SC ||
    roleFromLocalStorage === UserRoleEnum.SC_USER;

  const getUserRoleName = (role : string) => {
    switch (role) {
      case UserRoleEnum.GC:
      case UserRoleEnum.SC:
        return "Contractor";
      case UserRoleEnum.OWNER:
        return "Owner";
      default:
        return "";
    }
  };

  return { isOwner, isSubContractor,getUserRoleName };
};

export default UsePermission;
