import { useEffect } from 'react';
import OnBoardingFormContainer from '../OnboardingForm/onBoardingFormContainer';
import { setActiveStepAction } from '../../redux/actions/projectFormActions';
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import { ModalStyle } from '../../utils/const';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from '@fortawesome/free-solid-svg-icons';

const ProfileDetail = (props: any) => {


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveStepAction(0));
  }, []);


  return (
    <>
      <Modal
        id="profile"
        isOpen={props.modalIsOpen}
        style={ModalStyle}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content justify-content-center">
            <div className="modal-header d-flex justify-content-end border-bottom">
              <FontAwesomeIcon
                className="pointer"
                icon={faRemove}
                size="xl"
                onClick={props.handleClose}
              />
            </div>
            <div
              className="modal-body mt-4"
              style={{ width: "70vw", height: "82vh", overflowY: "hidden" }}
            >
              <OnBoardingFormContainer
                isProfileUpdating={true}
                handleClose={props.handleClose}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProfileDetail;