import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { CustomTable } from "../../DataTable";
import InputField from "../../InputField";
import { getUsersAddedByMe, userSearch } from "../../../utils/api";
import { getUserHeaderCells } from "../../../utils/const";
import SelectInputField from "../../SelectInputField/selectInputField";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { DataUtil } from "../../../utils/dataUtils";
import { SubmenuEnum } from "../../../utils/enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark, faSearch } from "@fortawesome/free-solid-svg-icons";

type Row = {
  id: any;
  first_name: string;
  email: string;
  phone_number: string;
  roleDesc:string;
  status: string;
};

type Values = {
  email: string;
  phoneNo: string;
  status: string;
};

const DefaultSearchFiltered = {
  email: "",
  phoneNo: "",
  roleDesc:"",
  status: ""
};

const UsersList = (props: any) => {
  const [rows, setRows] = useState<Row[]>([]);
  const [search, setSearch] = useState<Values>(DefaultSearchFiltered);

  useEffect(() => {
    fetchUserAddedByMeApi();
  }, []);

  const fetchUserAddedByMeApi = () => {
    getUsersAddedByMe("", undefined, undefined)
      .then((response: any) => {
        if (response.data) {
          const data = usersOfOrgList(response.data);
          setRows(data);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };

  const usersOfOrgList = (data: any[]) => {
    return (
      data &&
      data.map((item: any) => ({
        id: item.id,
        first_name: item.firstName + " " + item.lastName,
        email: item.email,
        phone_number: item.mobileNo
          .replace(/\D/g, "")
          .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
        roleDesc: item.roleDesc,
        status: item.status,
      }))
    );
  };

  const handleChange = (event: any) => {
    const { name, value } = event?.target;
    if (name === "phoneNo") {
      setSearch({
        ...search,
        [name]: value.replace(/\D/g, ""),
      });
    } else {
      setSearch({
        ...search,
        [name]: value,
      });
    }
  };

  const handleSearch = () => {
    const { email, phoneNo, status } = search;
    console.log("serach", search);
    const body = {
      email: email.replace(/[^a-zA-Z0-9@.]/g, "").trim(),
      mobileNo: phoneNo.replace(/[^0-9]/g, "").trim(),
      status: status.replace(/[^a-zA-Z0-9]/g, "").trim(),
    };

    if (body.email === "" && body.mobileNo === "" && body.status === "") {
      fetchUserAddedByMeApi();
    } else {
      userSearch("", undefined, body)
        .then((response) => {
          console.log("userSearch", response);
          if (response.status === "OK") {
            const data = usersOfOrgList(response.data);
            setRows(data);
          }
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    }
  };
  const clearInput = (name: string) => {
    setSearch({
      ...search,
      [name]: "",
    });

    const body = {
      email: name === 'email' ? '' : search.email.replace(/[^a-zA-Z0-9@.]/g, "").trim(),
      mobileNo: name === 'phoneNo' ? '' : search.phoneNo.replace(/[^0-9]/g, "").trim(),
      status: name === 'status' ? '' : search.status.replace(/[^a-zA-Z0-9]/g, "").trim(),
    };
    console.log('search', search)

    if (search.email === "" && search.phoneNo === "" && search.status === "") {
      fetchUserAddedByMeApi();
    } else {
      userSearch("", undefined, body)
        .then((response) => {
          console.log("userSearch", response);
          if (response.status === "OK") {
            const data = usersOfOrgList(response.data);
            setRows(data);
          }
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    }
  };
  return (
    <>
      <div>
        <div className="row gx-2 align-items-center">
          <div className="col-3">
            <InputField
              id="email"
              name="email"
              type="email"
              className="form-control"
              label="Email"
              handleChange={handleChange}
              value={search.email}
              endAdornment={
                search.email && (
                  <InputAdornment position="end">
                    <IconButton
                      name="email"
                      color="inherit"
                      onClick={() => clearInput("email")}
                      edge="end"
                    >
                      <ClearIcon name="email" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </div>
  
          <div className="col-3">
            <InputField
              id="phoneNo"
              name="phoneNo"
              type="text"
              className="form-control"
              label="Phone No."
              handleChange={handleChange}
              value={search.phoneNo.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}
              maxLength={10}
              endAdornment={
                search.phoneNo && (
                  <InputAdornment position="end">
                    <IconButton
                      color="inherit"
                      onClick={() => clearInput("phoneNo")}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </div>
  
          <div className="col-3">
            <SelectInputField
              id="status"
              name="status"
              handleChange={handleChange}
              label="Select Status"
              menuList={["", "Active", "Inactive"]}
              value={search.status}
            />
          </div>
          <div className="col-auto">
            <Button
              className="btn btn-secondary px-3"
              onClick={() => {
                setSearch({ ...DefaultSearchFiltered });  
                handleSearch();  
              }}
              title="Clear"
              aria-label="Clear search filters"
            >
              <FontAwesomeIcon icon={faFilterCircleXmark} size="lg" />
            </Button>
          </div>

          <div className="col-auto">
            <Button
              className="btn btn-primary px-3"
              onClick={handleSearch}
              disabled={DataUtil.isObjectsAreEqual(search, DefaultSearchFiltered)}
            >
              <FontAwesomeIcon icon={faSearch} size="lg" />
            </Button>
          </div>
        </div>
  
        <div className="row mt-3 gx-0">
          <div className="col-12 p-1">
            <CustomTable
              headCells={getUserHeaderCells}
              rows={rows}
              onSelectRowClick={(row: any) => {
                props.setBack(true);
                props.getSubMenu(SubmenuEnum.EDIT_USER);
                props.onEditSelect(row);
              }}
            />
          </div>
          <div className="col-1"></div>
        </div>
      </div>
    </>
  );
  
};

export default UsersList;
