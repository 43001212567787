import { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { Alert, Box, Button, IconButton, Snackbar, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React from "react";
import { faCheckCircle, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GlobalHeader from "../GlobalHeader";
import { useNavigate } from "react-router-dom";
import BasicDetails from "./FormSections/basicDetails";
import ClassiFicationDetails from "./FormSections/classiFicationDetails";
import Utils from "./utils";
import { OnboardFormSection } from "../../utils/const";
import SelectDivisionAndSpecification from "../SelectDivisionAndSpecification";
import CustomizedSnackbar from "../CustomizedSnackbar";
import { getProfileDetails, sendVerificationLink, signUp, updateProfile } from "../../utils/api";
import Loader from "../Loader";
import { useDispatch } from "react-redux";
import { setActiveStepAction } from "../../redux/actions/actions";
import CustomTooltipOverlay from "../CustomTooltipOverlay/customTooltipOverlay";
import { UserRoleEnum } from "../../utils/enum";
import UsePermission from "../../hooks/usePermission";
import CloseIcon from '@mui/icons-material/Close';


const style = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
    opacity: "1",
    background: "#f8f9fa",
  },
};

const OnboardRequest = ({ stepActive, isProfileUpdating, handleClose,handleDisplayProperMessage}: any) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [classificationAndLicence, setClassificationAndLicence] = useState({"classification":[],"licenceClassification":[]});
  const [currentProfileEmail, setCurrentProfileEmail] = useState("");
  const [open, setIsOpen] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);


  const dispatch = useDispatch();
  const isStepperVisible = useMemo(() => {
    return stepActive > -1;
  }, [stepActive]);
  const history = useNavigate();



  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    handleSubmit,
    setFieldError,
    setFieldTouched,
    touched,
    setTouched,
    isValid,
    setErrors,
  } = Utils(!isStepperVisible ? 0 : stepActive);


  const {getUserRoleName} = UsePermission();

  useEffect(() => {
    if (isProfileUpdating) {
      getProfileDetails(" ", undefined, undefined)
        .then(async (resp) => {
          console.log("resp.data", resp.data);
          if(resp.status === "OK"){
            const {
              orgName,
              firstName,
              lastName,
              mobileNo,
              roleDescription,
              organizationName,
              organizationCity,
              organizationState,
              addrInfo,
              email,
              classifications,
              licenseNo,
              taxNo,
              laborAffiation,
              dirNumber,
              licenseClassifications,
              dirDate,
              role
            } = resp.data;
            setClassificationAndLicence({
              classification: classifications,
              licenceClassification: licenseClassifications,
            });
            setCurrentProfileEmail(email);
            setFieldValue("orgName", orgName);
            setFieldValue("name",firstName + " " + lastName);
            setFieldValue("email",email);
            setFieldValue("phoneNumber",mobileNo);
            setFieldValue("zip",addrInfo["zip"]);
            setFieldValue("state",addrInfo["state"]);
            setFieldValue("city",addrInfo["city"]);
            setFieldValue("phoneNumber",mobileNo);
            setFieldValue("role", getUserRoleName(role));
            setFieldValue("addressOne",addrInfo["addressOne"]);
            setFieldValue("addressTwo",addrInfo["addressTwo"]);
            setFieldValue("taxNo",taxNo === null ? "" : taxNo);
            setFieldValue("licenseNo",licenseNo === null ? "" : licenseNo);
            setFieldValue("dirNumber",dirNumber);
            setFieldValue("dirDate",dirDate);
            setFieldValue("laborAffiation",laborAffiation);
          }else{
            setOpenSnackBar(true);
            setMessage(resp.message);
            await new Promise((resolve) => setTimeout(resolve, 2000));
            handleClose();
          }
        })
        .catch(async (error) => {
          console.log("e error:>> ", error);
        });
    }
  }, []);

  // console.log("stepActive values :>> ", values);

  const navigate = useNavigate();
  const totalPage = OnboardFormSection;

  const isUserOwner = useMemo(() => {
    return isProfileUpdating
      ? localStorage.getItem("role") === UserRoleEnum.OWNER
      : values.role === UserRoleEnum.OWNER;
  }, [values.role]);

  const createChildTradesStructure = (list: any, tempList: any) => {
    let tempTradeMap: any = tempList;
    list.forEach((value: any) => {
      tempTradeMap.push(value.id);
      const { id, children } = value;
      if (children && children.length > 0) {
        createChildTradesStructure(children, tempTradeMap);
      }
    });
    return tempTradeMap;
  };

  const handleFormSubmit = () => {
    dispatch(
      isUserOwner ? setActiveStepAction(3) : setActiveStepAction(stepActive + 1)
    );
    const {
      orgName,
      confirmPassword,
      name,
      email,
      phoneNumber,
      addressOne,
      addressTwo,
      state,
      zip,
      licenseNo,
      taxNo,
      city,
      laborAffiation,
      classifications,
      tradeList,
      dirNumber,
      dirDate,
      role,
      licenseClassifications,
    } = values;

    const classiFicationDetailsList = classifications
      .filter((value) => value.isSelected === true)
      .map((item) => item.id);

    const licenseClassificationsList = licenseClassifications
      .filter((value) => value.isSelected === true)
      .map((item) => item.id);

    let tempTradeList: any = [];
    let tradeResponseList: any = [];
    console.log("updated one", tradeList);
    tradeList.forEach((value: any) => {
      const { id, children } = value;
      if (children && children.length > 0) {
        tempTradeList = [
          ...tradeResponseList,
          ...createChildTradesStructure(children, [id]),
        ];
      } else {
        tempTradeList = [...tradeResponseList, id];
      }
      tradeResponseList = [...tempTradeList];
    });

    const body = {
      orgName,
      // password:encryptPassword(confirmPassword),
      password: confirmPassword,
      email,
      name,
      phoneNumber,
      addressOne,
      addressTwo,
      state,
      zip,
      city,
      licenseNo,
      taxNo,
      laborAffiation: laborAffiation,
      dirNumber,
      dirDate,
      classifications: classiFicationDetailsList,
      tradeIds: tradeResponseList,
      role: role,
      licenseClassifications: licenseClassificationsList,
    };
    console.log("object", !isUserOwner ? "GC" : role);

    if (isProfileUpdating) {
      const trimmedEmail = values.email.trim();
      const trimmedProfileEmail = currentProfileEmail.trim();
      updateProfile("", undefined, body)
        .then(async (response) => {
          if (response.status.includes("OK")) {
              setOpenSnackBar(true);
              setMessage(response.message);
              await new Promise((resolve) => setTimeout(resolve, 2000));
              handleClose();
              setOpenSnackBar(false);
              history("/login");
          } else {
            setMessage(response.message);
            setOpenSnackBar(true);
            dispatch(
              isUserOwner ? setActiveStepAction(0) : setActiveStepAction(2)
            );
            setFieldValue("tradeList", []);
          }
        })
        .catch((error: any) => {
          setMessage(error.message);
          setOpenSnackBar(true);
          setOpenSuccessModal(false);
          dispatch(setActiveStepAction(2));
          setFieldValue("tradeList", []);
        });
    } else {
      signUp("", undefined, body)
        .then(async (response) => {
          if (response.status.includes("OK")) {
            setOpenSuccessModal(true);
            setOpenSnackBar(false);
            const verificationResponse = await sendVerificationLink(
              "",
              undefined,
              body
            );
            if (verificationResponse.status.includes("OK")) {
            } else {
              console.log(
                "Failed to send verification link:",
                verificationResponse.message
              );
            }
          } else {
            setMessage(response.message);
            setOpenSnackBar(true);
            setOpenSuccessModal(false);
            dispatch(
              isUserOwner ? setActiveStepAction(0) : setActiveStepAction(2)
            );
            setFieldValue("tradeList", []);
          }
        })
        .catch((error: any) => {
          setMessage(error.message);
          setOpenSnackBar(true);
          setOpenSuccessModal(false);
          dispatch(setActiveStepAction(2));
          setFieldValue("tradeList", []);
        });
    }
  };

  const setTouchedField = () => {
    console.log('stepActive', stepActive)
    if (isUserOwner) {
      setTouched({
        name: true,
        email: true,
        phoneNumber: true,
        password: true,
        confirmPassword: true,
        state: true,
        city: true,
        zip: true,
        addressOne: true,
        addressTwo: true,
      });
    } else {
      switch (stepActive) {
        case 0:
          setTouched({
            name: true,
            orgName: true,
            email: true,
            phoneNumber: true,
            password: !isProfileUpdating,
            confirmPassword: !isProfileUpdating,
            state: true,
            city: true,
            zip: true,
            addressOne: true,
            addressTwo: true,
            taxNo: true,
            licenseNo: true,
          });
          if (values.orgName === "" || values.orgName === null) {
            setFieldError("orgName", "Required!");
          }
          break;
        case 1:
          setTouched({
            laborAffiation: true,
          });
          setFieldTouched("classifications", true);
          break;
        default:
          return null;
      }
    }
  
  };

  const handleAgreementProceed = () => {
    setShowAgreement(false);
    setIsCheckboxChecked(false);
    console.log("errors", errors);
    if (isValid) {
      debugger
      if (isUserOwner) {
        handleFormSubmit();
      } else if (stepActive === 2) {
        handleFormSubmit();
      } else {
        dispatch(setActiveStepAction(stepActive + 1));
      }
    } else {
      setTouchedField();
    }
   
  };

  const handleAgreementClose = () => {
    setShowAgreement(false); 
    setIsCheckboxChecked(false);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(e.target.checked);
  };

  return (
    <>
      {!isProfileUpdating && (
        <div
          className="d-flex justify-content-start mb-1"
          style={{ background: "#19255c" }}
        >
          <img
            src="BW logo-blue bg-svg.svg"
            width="310"
            height="56"
            alt="Login page"
            onError={(e) => {
              const img = e.target as HTMLImageElement;
              img.src = "image-not-found.png";
            }}
          />
        </div>
      )}
      <div
        className={`${
          isProfileUpdating ? "w-100 container-fluid" : "w-75 container"
        } " overflow-hidden fixedSize"`}
      >
        {isProfileUpdating ? (
          <></>
        ) : (
          <GlobalHeader
            title={`Create ${values.role ? values.role : "an"}  Account`}
            isLogoVisible
          />
        )}
        {!isStepperVisible ? (
          <AccountRoleCard
            handleClick={(role: string) => {
              console.log("role", role);
              setFieldValue("role", role);
              dispatch(setActiveStepAction(0));
            }}
          />
        ) : (
          <>
            {isUserOwner ? (
              <Typography
                component="h3"
                className="col-12 d-flex justify-content-center fs-3"
              >
                Basic Details
              </Typography>
            ) : (
              <Stepper activeStep={stepActive} alternativeLabel>
                {totalPage.map((page, index) => (
                  <Step key={page.id}>
                    <StepLabel
                      StepIconProps={{
                        style: {
                          color: index === stepActive ? "#19255c" : "#4a5274",
                        },
                      }}
                    >
                      {page.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
            <div
              className={`d-grid ${
                isProfileUpdating ? "" : "shadow"
              } mt-1 bg-light`}
            >
              <form
                className="form-group py-2"
                onSubmit={handleSubmit}
                style={{
                  height: "64vh",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                }}
              >
                <ConditionalComponent
                  formikProps={{
                    values,
                    errors,
                    setFieldValue,
                    setFieldError,
                    setErrors,
                    setFieldTouched,
                    setTouched,
                    touched,
                    handleChange,
                  }}
                  activeStep={stepActive}
                  isProfileUpdating={isProfileUpdating}
                  classificationAndLicence={classificationAndLicence}
                />
              </form>
            </div>
          </>
        )}
        {isStepperVisible && (
          <div className="row d-flex justify-content-center position-bottom bottom-25 mt-3">
            {!isUserOwner && (
              <div className="d-flex col-6 justify-content-end">
                <Button
                  className="btn h-75 w-25"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setActiveStepAction(stepActive - 1));
                    setTouched({
                      laborAffiation: false,
                    });
                    setTimeout(() => {
                      setErrors({});
                    }, 0);
                  }}
                  disabled={stepActive === 0}
                >
                  Back
                </Button>
              </div>
            )}
            <div
              className={
                isUserOwner
                  ? "d-flex col-12 justify-content-center"
                  : "d-flex col-6 justify-content-start"
              }
            >
              <Button
                className="btn w-25 h-75"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  console.log("errors", errors);
                  if (isValid || isProfileUpdating) {
                    if (isUserOwner) {
                      if(isProfileUpdating){
                        handleFormSubmit();
                      }
                      setShowAgreement(true);
                    } else if (stepActive === 2) {
                      if(isProfileUpdating){
                        handleFormSubmit();
                      }
                      setShowAgreement(true);
                    } else {
                      dispatch(setActiveStepAction(stepActive + 1));
                    }
                  } else {
                    setTouchedField();
                  }
                }}
              >
                {stepActive > 1
                  ? "Submit"
                  : isUserOwner
                  ? isProfileUpdating
                    ? "Update"
                    : "Create"
                  : "Continue"}
              </Button>
            </div>
          </div>
        )}
      </div>

      <Modal
        id="succes"
        isOpen={openSuccessModal}
        style={style}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <div className="mx-auto">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="bg-succes pointer"
                  style={{ color: "green" }}
                  size="4x"
                />{" "}
              </div>
            </div>
            <div
              className="modal-body mt-5"
              style={{ maxWidth: "400px", height: "150px" }}
            >
              <div className="row">
                <Typography className="gx-0 d-flex justify-content-center opacity-50 fs-5">
                  Sign-up will be completed once you verify your email address.
                  <br />A verification link has been sent to your email.
                </Typography>
              </div>
              <div className="row">
                <Button
                  className="btn btn-bg col-6 mx-auto gx-5 mt-5"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenSuccessModal(false);
                    dispatch(setActiveStepAction(0));
                    navigate("/login");
                  }}
                >
                  Back To Sign In
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <CustomizedSnackbar
        openSnackBar={openSnackBar}
        message={message}
        handleCloseSnackBar={() => setOpenSnackBar(false)}
      />
      <Snackbar
        open={open}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography
            className="d-flex size-sx"
            align="center"
            paragraph 
            style={{ color: "#333", fontWeight: "bold" }}
          >
            Your email address has been updated, and for security purposes,
            you’ve been logged out.
            Please log in again to verify your new email address and complete
            the process.
          </Typography>
        </Alert>
      </Snackbar>
      {!isProfileUpdating && 
        <AgreementModal 
        showAgreement = {showAgreement}
        handleAgreementClose = {handleAgreementClose}
       isCheckboxChecked = {isCheckboxChecked}
        handleCheckboxChange ={handleCheckboxChange}
         handleAgreementProceed={handleAgreementProceed} />}
    </>
  );
};

interface ChildProps {
  activeStep: number;
  formikProps: any;
  isProfileUpdating:boolean;
  classificationAndLicence: {
    classification: any;
    licenceClassification:any;
};
}

const ConditionalComponent: React.FC<ChildProps> = ({
  activeStep,
  formikProps,
  isProfileUpdating,
  classificationAndLicence
}: ChildProps) => {
  switch (activeStep) {
    case 0:
      return (
        <BasicDetails
          key="basicDetails-key"
          formikProps={formikProps}
          isProfileUpdating={isProfileUpdating}
        />
      );
    case 1:
      return (
        <ClassiFicationDetails
          key="classification-key"
          formikProps={formikProps}
          isProfileUpdating={isProfileUpdating}
          classificationAndLicence={classificationAndLicence}
        />
      );
    case 2:
      return (
        <SelectDivisionAndSpecification
          key="selectDivisionAndSpecification-key"
          formikProps={formikProps}
          isProfileUpdating={isProfileUpdating}
        />
      );
    default:
      return (
        <>
          <div>
            <Loader
              key="4"
              className="d-flex justify-content-center mt-5 py-5"
            />
          </div>
        </>
      );
  }
};

export default OnboardRequest;

interface AccountProps {
  handleClick: (role : string) => void;
}

const AccountRoleCard = ({ handleClick }: AccountProps) => {
  return (
    <>
      <Box
        className="border borderd-flex shadow mt-1 bg-light"
        sx={{
          height: "64vh",
          overflowX: "hidden",
          overflowY: "scroll",
          scrollbarWidth: "thin",
        }}
      >
        <div className="container">
          <div className="row p-5 mx-auto" style={{ marginTop: "15%" }}>
            <div className="col-6 col-md-6">
              <CustomTooltipOverlay
                title="The Owner will not able to invite sc"
              >
                <Box
                  className="card shadow w-75 mx-auto"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      transform: "translateX(-10px)",
                      transition: "transform 0.3s ease-in-out", 
                    },
                  }}
                  onClick={() => handleClick("Owner")}
                >
                  <div className="card-body text-center">
                    <Typography
                      variant="h4"
                      className="card-title fs-3"
                      sx={{
                        fontWeight: 500,
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      Owner Account
                    </Typography>
                  </div>
                </Box>
              </CustomTooltipOverlay>
            </div>

            <div className="col-6 col-md-6">
              <CustomTooltipOverlay
                title="The Contractor role is dependent on license classification"
                placement="bottom-start"
              >
                <Box
                  className="card shadow w-75 mx-auto"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      transform: "translateX(-10px)",
                      transition: "transform 0.3s ease-in-out",
                    },
                  }}
                  onClick={() => handleClick("Contractor")}
                >
                  <div className="card-body text-center">
                    <Typography
                      variant="h4"
                      className="card-title fs-3"
                      sx={{
                        fontWeight: 500,
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      Contractor Account
                    </Typography>
                  </div>
                </Box>
              </CustomTooltipOverlay>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

interface AgreementProps { 
  showAgreement:boolean;
  handleAgreementClose: ()=>void;
  isCheckboxChecked: boolean;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>)=>void; 
  handleAgreementProceed: ()=>void;
}

const AgreementModal = ({showAgreement, handleAgreementClose, isCheckboxChecked, handleCheckboxChange, handleAgreementProceed} : AgreementProps)=>{
  return(
     <Modal isOpen = {showAgreement}
     style={{
      content: {
        width: '80%', 
        maxWidth: '900px',  
        height: '90vh',  
        margin: 'auto',
        padding: '10px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0,0,0,0.3)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', 
        zIndex: 1050,
      }
    }}
     >
                  <div className="row gx-0 col-12 p-0">
                  <h2 className="col-11">Agreement Document</h2>
                    <div className="col-1 d-flex justify-content-end">
                      <FontAwesomeIcon
                        className="pointer "
                        icon={faClose}
                        size="xl"
                        onClick={handleAgreementClose}
                      />
                    </div>
                  </div>
                <iframe
                  src="/terms.pdf"
                  style={{
                    display: 'flex', 
                    width: '100%', 
                    height: '80%', 
                    border: 'none',  
                    borderRadius: '0px',
                    padding: '10px 0',         
                  }}        
                  title="Agreement Document">
                </iframe>

                <div>
                  <input
                    type="checkbox"
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label className="ms-2">
                     I have read and agree to the terms and conditions.
                  </label>
                </div>
                <div className="mt-1">
                  <Button onClick={handleAgreementProceed}
                  disabled={!isCheckboxChecked} 
                  style={{
                    opacity: isCheckboxChecked ? 1 : 0.5,  
                    pointerEvents: isCheckboxChecked ? 'auto' : 'none', 
                  }}
                  >Proceed</Button>
                  <Button onClick={handleAgreementClose} className="ms-3">Cancel</Button>
                </div>
          </Modal>
  );
}