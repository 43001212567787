import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomTable } from "../DataTable";
import { useEffect, useState } from "react";

const style = [
  { width: "100%", height: "60vh", overflowX: "hidden", overflowY: "scroll" },
];
interface Props {
  file: File;
  headers?: any[];
  rows?: any[];
}

const FilePreview = ({ file, headers=[], rows=[], ...props }: Props) => {

  const [url, setUrl] = useState('');

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setUrl(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [file]);
  
  try {
    const fileType = file.type || getFileTypeFromName(file.name);
    switch (fileType) {
      case "application/pdf":
        return (
          <embed
            src={url}
            type="application/pdf"
            width="1200px"
            height="600px"
            aria-label="PDF Document"
            title="PDF Viewer"
            onLoad={(e) => {
              console.log('first', e)
            }
            
            }

          />
        );
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        return (
          <img
            src={url}
            alt="modalImage"
            style={{
              maxWidth: "600",
              maxHeight: "575px",
              display: "flex",
              justifyContent: "center",
            }}
            onError={(e) => {
              const img = e.target as HTMLImageElement;
              img.src = "image-not-found.png";
            }}
          />
        );
      case "application/csv":
        return (
          <div className="container d-flex justify-content-center w-100">
            <div className="mt-5 h-100" style={{overflow:"auto"}} >
              <CustomTable headCells={headers} rows={rows} style={style} />
            </div>
          </div>
        );
     
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return (
          <iframe
            width="100%"
            height="100%"
            title="DOCX Viewer"
            style={{ border: "none" }}
          />
        );

        case "application/vnd.ms-excel":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return (
              <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
                width="100%"
                height="600px"
                title="Excel Document Viewer"
                style={{ border: "none" }}
              />
            );

            case "text/plain":
              return (
                <div className="container">
                  <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                    {url}
                  </pre>
                </div>
              );

      default:
        return (
          <div style={{ width: "575px", height: "90%" }}>
            <div className="row col-12 mt-1">
              <FontAwesomeIcon
                className="text-danger"
                icon={faWarning}
                color="warning"
                size="8x"
              ></FontAwesomeIcon>
            </div>
            <div className="row col-12 mt-2 ms-1">
              <p className="opacity-50 fs-5">
                <span className="d-flex justify-content-center gx-5">
                  Invalid file.
                </span>
                <span className="d-flex justify-content-center">
                  Please select a valid file.
                </span>
              </p>
            </div>
          </div>
        );
    }
  } catch (error) {
    return (
      <img
        alt="modalImage"
        style={{ maxWidth: "575px", maxHeight: "575px" }}
        onError={(e) => {
          const img = e.target as HTMLImageElement;
          img.src = "image-not-found.png";
        }}
      />
    );
  }
};
function getFileTypeFromName(fileName: string): string {
  const extension = fileName.split('.').pop()?.toLowerCase();
  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'png':
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg'; // Use a common MIME type for images
    case 'csv':
      return 'application/csv';
    case 'doc':
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'xls':
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'txt':
      return 'text/plain';
    default:
      return 'unknown'; // Unknown or unsupported file type
  }
}

export default FilePreview;
