import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputField from "../../InputField";
import { useSelector } from "react-redux";
import { selectThemeColor } from "../../../redux/selectors/selectors";
import Utils from "../utils";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import { addUser, updateUser } from "../../../utils/api";
import { DataUtil } from "../../../utils/dataUtils";

const CreateUser = (props: any) => {

  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isEdit =
    props.selectedRows.id === -1 || props.selectedRows.id === undefined
      ? true
      : false;

  const color = useSelector(selectThemeColor);
  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    touched,
    isSubmit,
    onSubmit,
    setFieldTouched,
    isValid
  } = Utils();


  useEffect(() => {
    debugger
    console.log('selectedRows', isEdit)
    if (!isEdit) {
      console.log('first', props.selectedRows)
      setFieldValue("id", props.selectedRows.id);
      setFieldValue("firstName", props.selectedRows.firstName);
      setFieldValue("lastName", props.selectedRows.lastName);
      setFieldValue("email", props.selectedRows.email);
      setFieldValue("phoneNo", props.selectedRows.phoneNo);
      setFieldValue("roleDesc", props.selectedRows.roleDesc);
      if( props.selectedRows.status == undefined){
        setFieldValue(
          "isActive",
          props.selectedRows.isActive
        );
      } else{
        setFieldValue(
          "isActive",
          props.selectedRows.status === "P" ? false : true
        );
      }
     
    } else{
      setFieldValue(
        "isActive",
        props.selectedRows.status === "P" ? false : true
      );
    }
  }, []);

  const handleSave = () => {
    setIsLoading(true);
    console.log('isEdit', isEdit)
    debugger
      const body = {
        id: isEdit ?  "" : values.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        mobileNo: values.phoneNo,
        roleDesc:values.roleDesc,
        status: values.isActive ? `A` : `P`,
      };
      addUser("", undefined, body)
        .then(async (response) => {
          console.log("response for new user:", response);
          if (response.status === "OK") {
            setIsOpen(true);
            setMessage("successful!");
            await DataUtil.setTimeoutPromiseFunction(1000);
            setIsLoading(false);
            props.setBackButton(false);
          } else {
            setMessage(response.message);
            setIsOpen(true);
            setIsLoading(false);
          }
        })
        .catch((error: any) => {
          setMessage(error.message);
          setIsOpen(true);
          setIsLoading(false);
        });
  };

  return (
    <>
      <div className="overflow-hidden">
        <div className="d-grid shadow" style={{ backgroundColor: "#F8F9FA" }}>
          <div
            className="container w-45 overflow-y-scroll"
            style={{ height: "57vh" }}
          >
            <div className="row justify-content-center mt-5">
              <div className="col-4">
                <InputField
                  id="firstName"
                  name="firstName"
                  type="text"
                  maxLength={30}
                  value={values.firstName}
                  handleChange={handleChange}
                  className="form-control"
                  label="First Name"
                  handleBlur={() => setFieldTouched("firstName", true)}
                  error={touched.firstName && errors.firstName}
                  required={isEdit}
                />
              </div>
              <div className="col-4 col-md-4 col-sm-4">
                <InputField
                  id="lastName"
                  name="lastName"
                  type="text"
                  maxLength={30}
                  value={values.lastName}
                  handleChange={handleChange}
                  className="form-control"
                  label="Last Name"
                  handleBlur={() => setFieldTouched("lastName", true)}
                  error={touched.lastName && errors.lastName}
                  required={isEdit}
                />
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-4 col-md-4 col-sm-4">
                <InputField
                  id="phoneNo"
                  name="phoneNo"
                  type="text"
                  value={values.phoneNo
                    .replace(/\D/g, "")
                    .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}
                  maxLength={10}
                  handleChange={handleChange}
                  className="form-control"
                  label="Phone Number"
                  handleBlur={() => setFieldTouched("phoneNo", true)}
                  error={touched.phoneNo && errors.phoneNo}
                  required={isEdit}
                />
              </div>
              <div className="col-4 col-md-4 col-sm-4">
                <InputField
                  id="email"
                  name="email"
                  type="text"
                  maxLength={50}

                  value={values.email}
                  handleChange={handleChange}
                  className="form-control"
                  label="Email"
                  handleBlur={() => setFieldTouched("email", true)}
                  error={touched.email && errors.email}
                  required={isEdit}
                />
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-8">
              <InputField
                id="roleDesc"
                name="roleDesc"
                type="text"
                value={values.roleDesc}
                handleChange={handleChange}
                maxLength={20}
                className="form-control"
                label="User Role(This field dont have any impact)"
                handleBlur={() => setFieldTouched("roleDesc", true)}
                error={touched.roleDesc && errors.roleDesc}
              />
              </div>
            </div>

            <div className="">
              <div
                className="d-flex align-items-center justify-content-end"
                style={{ marginRight: "17%" }}
              >
                <Checkbox
                  className=""
                  checked={values.isActive}
                  style={{ color: color.darkColor, width: "15px" }}
                  size="medium"
                  key="yes"
                  onChange={(e) => {
                    setFieldValue("isActive", !values.isActive);
                  }}
                />
                {isSubmit && touched.isActive && errors.isActive && (
                  <span className="text-danger">{errors.isActive}</span>
                )}
                <div className="ms-2">Is Active</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center mt-4">
          <Button
            className="btn col-2 col-md-2 col-sm-2 p-2"
            onClick={(e) => {
              e.preventDefault();
              props.setBackButton(false);
            }}
            style={{ backgroundColor: color.darkColor, color: "white" }}
          >
            Cancel
          </Button>
          <Button
            className="btn col-2 col-md-2 col-sm-2 p-2 ms-3"
            onClick={(e) => {
              e.preventDefault();
              if (isValid) {
                handleSave();
              } else {
                setFieldTouched("firstName", true);
              }
            }}
            style={{ backgroundColor: color.darkColor, color: "white" }}
          >
            {isLoading ? (
              <CircularProgress
                className="ms-1"
                color="info"
                variant="indeterminate"
                thickness={3}
                size={26}
              />
            ) : !isEdit ? (
              "Update"
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </div>
      <CustomizedSnackbar
        openSnackBar={isOpen}
        message={message}
        handleCloseSnackBar={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default CreateUser;
